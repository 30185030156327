/* eslint-disable max-lines */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';

import MetaRobots from '../component/MetaRobots';
import Opengraph from '../component/Opengraph';
import TwitterCard from '../component/TwitterCard';

export class ProductPageComponentPlugin {
    getStarCounts(product) {
        const {
            review_count = 0,
            rating_summary = 0,
            best_rating,
            worst_rating
        } = this.getReviewSummary(product);
        const STARS_GRANULARITY_PERCENT = 10;
        const ONE_STAR_SHARE = 20;
        const percentRounded = Math.round(rating_summary / STARS_GRANULARITY_PERCENT) * STARS_GRANULARITY_PERCENT;
        const full_count = Math.floor(percentRounded / ONE_STAR_SHARE);

        return {
            review_count, full_count, best_rating, worst_rating
        };
    }

    getReviewsAuthors() {
        const { parentProduct: { variants = [] } = {} } = BrowserDatabase.getItem('PARENT_PRODUCT') || {};

        if (variants.length !== 0) {
            const x = variants.reduce((result, variant) => {
                const {
                    reviews: {
                        items = []
                    } = {}
                } = variant;

                if (items.length !== 0) {
                    const reviewAuthors = items.reduce((result, review) => {
                    // eslint-disable-next-line no-prototype-builtins
                        const ONE_STAR_SHARE = 20;
                        const {
                            average_rating = 0,
                            nickname = '',
                        } = review;
                        const obj = {
                            '@type': 'Review',
                            'reviewRating': {
                                '@type': 'Rating',
                                'ratingValue': average_rating / ONE_STAR_SHARE
                            },
                            'author': {
                                '@type': 'Person',
                                'name': nickname
                            },
                        };

                        return { ...result, ...obj };
                    }, {});
                    result.push(reviewAuthors);
                }

                return result;
            }, []);

            return x;
        }

        return null;
    }

    getReviewSummary = (product = {}) => {
        const {
            type_id: type,
            parent_id,
        } = product;

        if ((type === 'simple' || type === 'virtual') && parent_id !== null) {
            const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem('PARENT_PRODUCT') || {};

            if (parent_id === parentID) {
                const {
                    variants = [],
                } = cachedParentProduct;

                const summary = [];
                const reviewsCount = variants.reduce((acc, variant) => {
                    if (!variant.rating_summary || Number(variant.rating_summary) === 0) {
                        return acc;
                    }

                    summary.push(Number(variant.rating_summary));

                    return {
                        review_count: acc.review_count + Number(variant.review_count),
                        rating_summary: (acc.rating_summary + Number(variant.rating_summary)),
                    };
                }, {
                    review_count: 0, rating_summary: 0
                });

                const ratingSummary = summary.length ? reviewsCount.rating_summary / summary.length : 0;

                const ratings = variants.reduce((acc, variant) => {
                    if (!variant.rating_summary || Number(variant.rating_summary) === 0) {
                        return acc;
                    }

                    const share = 20;

                    if (variant.rating_summary / share > acc.best_rating) {
                        acc.best_rating = variant.rating_summary / share;
                    }

                    if (variant.rating_summary / share < acc.worst_rating) {
                        acc.worst_rating = variant.rating_summary / share;
                    }

                    return acc;
                }, {
                    best_rating: -1, worst_rating: 6
                });

                return {
                    review_count: reviewsCount.review_count,
                    rating_summary: ratingSummary,
                    best_rating: ratings.best_rating,
                    worst_rating: ratings.worst_rating
                };
            }
        }

        return false;
    };

    getDescription = (props) => {
        const {
            dataSource: {
                parent_id,
                description: { html: htmlDescription = '' } = {},
                short_description: { html: shortHtmlDescription = '' } = {},
            },
        } = props;

        const descriptionToReduce = htmlDescription !== '' ? htmlDescription : shortHtmlDescription;
        const cleanHtmlDescription = descriptionToReduce.replace(/<\/?[^>]+(>|$)/g, '');

        if (!cleanHtmlDescription) {
            const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem('PARENT_PRODUCT') || {};

            if (parent_id === parentID && cachedParentProduct) {
                const {
                    description: {
                        html: parentHtml,
                    } = {},
                } = cachedParentProduct;

                return parentHtml.replace(/<\/?[^>]+(>|$)/g, '');
            }
        }

        return cleanHtmlDescription;
    };

    renderProductMicroData = (props) => {
        const { activeProduct = {} } = props;

        if (!activeProduct) {
            return null;
        }
        const {
            name = '',
            sku = '',
            stock_status = '',
            type_id = '',
            media_gallery_entries = [],
            variants = [],
            price_range: {
                maximum_price: { final_price: maximumFinalPrice = 0 } = {},
                minimum_price: { final_price: minimumFinalPrice = 0 } = {}
            } = {},
            attributes: {
                manufacturer: {
                    attribute_options = {}
                } = {},
                gtin: {
                    attribute_value: gtin_value = 0
                } = {},
                mpn: {
                    attribute_value: mpn_value = ''
                } = {}
            } = {}
        } = activeProduct;

        const {
            review_count, full_count, // best_rating, worst_rating
        } = this.getStarCounts(activeProduct);

        const availability = stock_status === 'IN_STOCK' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
        const description = this.getDescription(props);
        const reviewAuthors = this.getReviewsAuthors();
        const gallery = media_gallery_entries.map((entry) => entry.thumbnail.url);

        const productRichSnippet = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            'name': name,
            'sku': sku,
        };

        if (gtin_value !== 0 && gtin_value !== null) {
            productRichSnippet.gtin13 = gtin_value;
        }

        if (mpn_value !== '' && mpn_value !== null) {
            productRichSnippet.mpn = mpn_value;
        }

        if (gallery.length !== 0) {
            productRichSnippet.image = gallery;
        }

        if (Object.keys(attribute_options).length !== 0 && Object.values(attribute_options)[0]?.label) {
            productRichSnippet.brand = {
                '@type': 'Brand',
                'name': Object.values(attribute_options)[0]?.label
            };
        }

        if (reviewAuthors != null && reviewAuthors.length !== 0) {
            productRichSnippet.review = reviewAuthors;
        }

        if (description !== '') {
            productRichSnippet.description = description;
        }

        if (review_count > 0) {
            productRichSnippet.aggregateRating = {
                '@type': 'AggregateRating',
                'ratingValue': full_count,
                'reviewCount': review_count,
                // 'worstRating': worst_rating,
                // 'bestRating': best_rating
            };
        }

        if (type_id !== 'simple') {
            productRichSnippet.offers = {
                '@type': 'AggregateOffer',
                'url': window.location.href,
                'priceCurrency': maximumFinalPrice.currency,
                'lowPrice': minimumFinalPrice.value,
                'highPrice': maximumFinalPrice.value,
                'offerCount': variants.length
            };
        } else {
            productRichSnippet.offers = {
                '@type': 'Offer',
                'url': window.location.href,
                'priceCurrency': maximumFinalPrice.currency,
                'price': maximumFinalPrice.value,
                'availability': availability
            };
        }

        return (
            <script type="application/ld+json">
                { JSON.stringify(productRichSnippet) }
            </script>
        );
    };

    renderOpengraph = (props) => (
        <Opengraph
          type="product"
          entity={ props.activeProduct }
        />
    );

    renderTwitterCard = (props) => (
        <TwitterCard
          type="product"
          entity={ props.activeProduct }
        />
    );

    renderMetaRobots = (props) => (
        <MetaRobots
          type="product"
          entity={ props.activeProduct }
        />
    );

    render = (args, callback, instance) => (
        <>
            <Helmet>
                { this.renderProductMicroData(instance.props) }
            </Helmet>
          { createPortal({ ...this.renderMetaRobots(instance.props) }, document.head) }
          { createPortal({ ...this.renderOpengraph(instance.props) }, document.head) }
          { createPortal({ ...this.renderTwitterCard(instance.props) }, document.head) }
          { callback(...args) }
        </>
    );
}

const {
    render,
} = new ProductPageComponentPlugin();

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render,
        },
    },
};
