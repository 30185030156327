/* eslint-disable react/no-unknown-property */
import { PureComponent } from 'react';
import PinchZoomPan from "../PinchZoomPan";

import './ZoomImage.style';

/** @namespace Efex/MorhanePdpDiscount/Component/ZoomImage/Component */
export class ZoomImageComponent extends PureComponent {
    state = {
        backgroundPosition: `0% 0%`
    };

    handleMouseMove(e) {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        this.setState({ backgroundPosition: `${x}% ${y}%` })
    }

    render() {
        const { index, baseSrc, largeSrc, isMobile } = this.props;

        if (isMobile) {
            return (
                <div block='ProductGallery' elem='SliderImageMobileBlock'>
                    <PinchZoomPan
                      zoomButtons={ false }
                      initialScale={1}
                      minScale={1}
                      maxScale={2.5}
                      position='center'
                      doubleTapBehavior={'zoom'}
                    >
                        <img alt={ index } src={ baseSrc } />
                    </PinchZoomPan>
                </div>
            );
        }

        return (
            <div block='ProductGallery' elem='SliderImageBlock' key={ index }>
                <figure onMouseMove={(e) => this.handleMouseMove(e)} style={{ backgroundImage: `url(${largeSrc})`, ...this.state }}>
                    <img alt={ index } src={ baseSrc } />
                </figure>
            </div>
        );
    }
}

export default ZoomImageComponent;
