/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getProductDetailsData, getProductPriceData } from '../data/product';
import { GTM_EVENT_KEY_PRODUCT_DETAIL } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireNotFoundEvent */
export const fireViewItemEvent = debounceCallback(async (product) => {
    const { price, discount, currencyCode } = await getProductPriceData(product);
    const productDetails = await getProductDetailsData(product);
    const totalValue = price - discount;
    pushToDataLayer({
        event: GTM_EVENT_KEY_PRODUCT_DETAIL,
        ecommerce: {
            currency: currencyCode,
            value: Number(totalValue),
            ...productDetails,
        },
    });
});
