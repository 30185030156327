import { Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

export const PARENT_PRODUCT = 'PARENT_PRODUCT';

export const ProductAttributes = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductAttributes'
));
export const ProductInformation = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductInformation'
));
export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
));

/** @namespace Efex/MorhanePdpAttributes/Plugin/Route/ProductPage/isProductInformationTabEmpty */
export const isProductInformationTabEmpty = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);

    if (originalElement) {
        const { parent_id } = instance.getDataSource();
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (parent_id === parentID && cachedParentProduct) {
            const { description: { html = '' } = {} } = cachedParentProduct;
            const htmlElement = new DOMParser().parseFromString(html, 'text/html');

            return !htmlElement?.body?.innerHTML;
        }
    }

    return originalElement;
};

/** @namespace Efex/MorhanePdpAttributes/Plugin/Route/ProductPage/renderProductAttributesTab */
export const renderProductAttributesTab = (args, callback, instance) => {
    const key = args[0];
    const {
        activeProduct,
        activeProduct: {
            parent_id,
            attributes: activeAttr = {},
        },
        areDetailsLoaded,
    } = instance.props;

    const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

    if (parent_id === parentID && cachedParentProduct) {
        const {
            attributes: productAttr = {},
        } = cachedParentProduct;

        const attributes = {};

        Object.keys(activeAttr).forEach((attr) => {
            const { [ attr ]: { attribute_value: activeAttrValue, attribute_options: activeAttrOptions }, [ attr ]: currAttr } = activeAttr;
            const { [ attr ]: { attribute_value: attrValue = '', attribute_options: attrOptions } = {} } = productAttr;

            attributes[ attr ] = {
                ...currAttr,
                attribute_options: activeAttrValue ? activeAttrOptions : attrOptions,
                attribute_value: activeAttrValue || attrValue,
            };
        });

        Object.keys(productAttr).forEach((attr) => {
            if (!activeAttr[ attr ]) {
                const { [ attr ]: parentAttr } = productAttr;
                attributes[ attr ] = parentAttr;
            }
        });

        const product = {
            ...activeProduct,
            attributes,
        };

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductAttributes
                    product={ product }
                    areDetailsLoaded={ areDetailsLoaded }
                    key={ key }
                />
            </Suspense>
        );
    }

    return callback.apply(instance, args);
};

/** @namespace Efex/MorhanePdpAttributes/Plugin/Route/ProductPage/renderProductInformationTab */
export const renderProductInformationTab = (args, callback, instance) => {
    const key = args[0];

    const {
        dataSource: {
            parent_id,
            description: {
                html
            } = {},
            areDetailsLoaded,
        },
    } = instance.props;
  
    if (!html) {
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (parent_id === parentID && cachedParentProduct) {
            const {
                description: {
                    html: parentHtml,
                } = {},
            } = cachedParentProduct;

            return (
                <Suspense fallback={ null }>
                    <ProductInformation
                      htmlDescription={ parentHtml }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            );
        }
    }

    return callback.apply(instance, args);
};

/** @namespace Efex/MorhanePdpAttributes/Plugin/Route/ProductPage/renderProductActions */
export const renderProductActions = (args, callback, instance) => {
    const {
        dataSource: {
            parent_id,
            short_description: {
                html
            } = {},
        },
        getLink,
        dataSource,
        areDetailsLoaded,
        setActiveProduct,
        parameters,
        isMobile,
    } = instance.props;
  
    if (!html) {
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (parent_id === parentID && cachedParentProduct) {
            const {
                short_description = {},
            } = cachedParentProduct;

            const product = {
                ...dataSource,
                short_description,
            };

            return (
                <div block="ProductPage" elem="ProductActions">
                    { !isMobile && instance.renderProductDesktopMainData() }
                    <Suspense fallback={ instance.renderProductActionsPlaceholder() }>
                        <ProductActions
                        getLink={ getLink }
                        product={ product }
                        parameters={ parameters }
                        areDetailsLoaded={ areDetailsLoaded }
                        setActiveProduct={ setActiveProduct }
                        />
                    </Suspense>
                </div>
            );
        }
    }

    return callback.apply(instance, args);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            isProductInformationTabEmpty
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductInformationTab,
            renderProductAttributesTab,
            renderProductActions
        }
    }
};
