import { Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import { ProductType } from 'Component/Product/Product.config';
import ProductListQuery from 'Query/ProductList.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { fetchQuery } from 'Util/Request/Query';

export const PARENT_PRODUCT = 'PARENT_PRODUCT';

export const ProductReviews = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-misc" */
    'Component/ProductReviews'
));

/** @namespace Efex/MorhanePdpReviews/Plugin/Route/ProductPage/renderProductReviewsTab */
export const renderProductReviewsTab = (args, callback, instance) => {
    const key = args[0];
    const {
        dataSource,
        areDetailsLoaded,
    } = instance.props;

    const newDataSource = getNewDataSource(dataSource);

    if (newDataSource !== false) {
        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductReviews
                  product={ newDataSource }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    return callback.apply(instance, args);
};

/** @namespace Efex/MorhanePdpReviews/Plugin/Route/ProductPage/renderProductActions */
export const renderProductActions = (args, callback, instance) => {
    const {
        dataSource: {
            parent_id,
        },
    } = instance.props;
  
    getParentProduct(parent_id);
    return callback.apply(instance, args);
};

/** @namespace Efex/MorhanePdpReviews/Plugin/Component/ProductPage/getNewDataSource */
const getNewDataSource = (dataSource = {}) => {
    const {
        type_id: type,
        parent_id,
    } = dataSource;

    const { review_count,  rating_summary, review_summary,  reviews, ...restOfProduct } = dataSource;

    if ((type === ProductType.SIMPLE || type === ProductType.VIRTUAL) && parent_id !== null) {
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (parent_id === parentID) {
            const {
                configurable_options,
                variants = [],
            } = cachedParentProduct;

            const summary = [];
            const ONE_FIFTH_OF_A_HUNDRED = 20;

            const reviewsCount = variants.reduce((acc, variant) => {
                if (variant.reviews.items.length < 1 || Number(variant.rating_summary) < 1) {
                    return acc;
                }

                summary.push(Number(variant.rating_summary));
                const { url, reviews: { items } = {} } = variant;
                const variantData = getAttributes(variant, configurable_options);

                const variantRevies = items.reduce((accumulator, review) => {
                    const { rating_votes = [], ...restOfReview } = review;

                    const newRatingVotes = rating_votes.reduce((ratingVotesAcc, vote) => {
                        const { rating_code, value } = vote;

                        return [
                            ...ratingVotesAcc,
                            {
                                rating_code,
                                value,
                                percent: Number(value) * ONE_FIFTH_OF_A_HUNDRED,
                            },
                        ];
                    }, []);

                    return [
                        ...accumulator,
                        {
                            ...restOfReview,
                            link: url,
                            variant_data: variantData,
                            rating_votes: newRatingVotes,
                        },
                    ];
                }, []);

                return {
                    review_count: acc.review_count + Number(variant.review_count),
                    rating_summary: (acc.rating_summary + Number(variant.rating_summary)),
                    reviews : [...acc.reviews, ...variantRevies],
                };
            }, { review_count: 0, rating_summary: 0, reviews: [] });
        
            const ratingSummary = summary.length ? reviewsCount.rating_summary / summary.length : 0;
            return {
                ...restOfProduct,
                reviews: reviewsCount.reviews,
                review_count: reviewsCount.review_count,
                rating_summary: ratingSummary,
                review_summary: {
                    review_count: reviewsCount.review_count,
                    rating_summary: ratingSummary,
                },
            }
        }
    }

    return false;
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/getParentProduct */
const getParentProduct = async (productID) => {
    try {
        const { parentID } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (productID === parentID){
            return BrowserDatabase.getItem(PARENT_PRODUCT);
        }

        const { products: { items } } = await fetchQuery(ProductListQuery.getQuery({
            args: {
                filter: {
                    productID,
                },
            },
            isSingleProduct: true,
        }));

        const [product] = items;
        const parentProduct = getIndexedProduct(product);

        const parentProductData = {
            parentID: productID,
            parentProduct: parentProduct,
        };
        BrowserDatabase.setItem(parentProductData, PARENT_PRODUCT);
    } catch (e) {
        return null;
    }
};

/** @namespace Efex/MorhanePdpReviews/Plugin/Component/ProductPage/getAttributes */
const getAttributes = (variant, configurable_options = {}) => {
    const { attributes = [] } = variant || {};

    return attributes ? Object.values(attributes).reduce((
        acc,
        { attribute_code, attribute_label, attribute_value },
    ) => {
        const {
            attribute_options: {
                [ attribute_value ]: {
                    value = '',
                    label = '',
                } = {},
            } = {},
        } = configurable_options[ attribute_code ] || {};

        if (value === attribute_value) {
            acc.push( { label: attribute_label, value: label } );

            return acc;
        }

        return acc;
    }, []) : [];
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductReviewsTab,
            renderProductActions
        },
    },
};
