/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/handleSliderClick */
export const handleImageZoomPopupActiveChange = (args, callback, instance) => {
    const isImageZoomPopupActive = args[0];
    instance.setState({ isImageZoomPopupActive });
};

export default {
    'Component/ProductGallery/Component': {
        'member-function': {
            handleImageZoomPopupActiveChange,
        },
    },
};
