import { Suspense } from 'react';
import { getVariantIndex, getAttributesWithValues } from 'Util/Product';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
export const ProductActions = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays" */
    'Component/ProductActions'
));

/** @namespace Efex/MorhanePdpStock/Plugin/Route/ProductPage/renderShortDescription */
export const renderProductActions = (args, callback, instance) => {
    const {
        activeProduct,
        getLink,
        dataSource,
        parameters,
        areDetailsLoaded,
        setActiveProduct,
        dataSource: {
            name: title,
        },
        isMobile,
    } = instance.props;

    const attributes = getAttributesWithValues(dataSource);
    const manufacturerAttr = Object.values(attributes).filter(
        (attribute) => attribute.attribute_code === 'manufacturer',
    );
    const isBrand = manufacturerAttr.length ? true : false;
    const brand = isBrand ? manufacturerAttr[0].attribute_options[manufacturerAttr[0].attribute_value]?.label : '';

    return (
        <div block="ProductPage" elem="ProductActions">
        <meta itemProp="name" content={ title } />
            { !isMobile && (
                <>
                    { isBrand ? (
                        <>
                            <meta itemProp="brand" content={ brand } />
                            <h4 block="ProductPage" elem="Brand">
                                <TextPlaceholder content={ brand } />
                            </h4>
                        </>
                    ) : null }
                    <h1 block="ProductPage" elem="Title">
                        <TextPlaceholder content={ title } length={ TextPlaceHolderLength.MEDIUM } />
                    </h1>
                </>
            ) }
            <Suspense fallback={ null }>
                <ProductActions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }
                  activeProduct={ activeProduct }
                />
            </Suspense>
        </div>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductActions
        },
    },
};
