/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import { addHreflangTags } from '../../util/AddHreflangTags.js';
import HreflangUrlsQuery from '../query/HreflangUrls.query';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
        urlRewrite: state.UrlRewritesReducer.urlRewrite,
    };
};

export class ProductPageContainerPlugin {
    constructExtended = (args, callback, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.state = {
            hreflangUrls: [],
        };
    };

    componentDidUpdate = (args, callback, _instance) => {
        const { productID, seoConfigurations, urlRewrite: { type } } = _instance.props;
        const isActive = seoConfigurations?.hreflang?.isActive ?? false;
        // eslint-disable-next-line no-unused-expressions
        if (type !== 'AMASTY_XLANDING_PAGE') {
            isActive && fetchQuery(HreflangUrlsQuery.getHreflangUrls(productID, 'product'))
                .then(
                    (response) => {
                        addHreflangTags(response?.hreflangUrls || []);
                    }
                );
        }
        callback(...args);
    };
}

const {
    componentDidUpdate,
    constructExtended,
} = new ProductPageContainerPlugin();

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate,
            __construct: constructExtended,
        },
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps,
            },
        ],
    },
};
