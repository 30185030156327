import { cloneElement } from 'react';
import { MediaType } from 'Component/ProductGallery/ProductGallery.config';
import { CAROUSEL_ITEM_GAP, ARROW_SAFE_AREA } from 'Component/CarouselScroll/CarouselScroll.config';
import ZoomImage from '../../component/ZoomImage';
import '../../component/ProductGallery/ProductGallery.style';

/** @namespace Efex/MorhanePdpGallery/Plugin/Route/ProductPage/componentDidUpdate */
export const componentDidUpdate = (args, callback, instance) => {
    callback.apply(instance, args);

    const { activeImage: prevActiveImage} = args[0];
    const { activeImage } = instance.props;

    if (prevActiveImage !== activeImage) {
        const matchedElements = document.querySelectorAll('.ProductGallery-SliderImageMobileBlock img');
        matchedElements.forEach((element, key) => {
            if (prevActiveImage === key) {
                element.style.transform = 'none';
            }
        });
    }
};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/renderImage */
export const renderImage = (args, callback, instance) => {
    const {
        isMobile,
    } = instance.props;

    const {
        base: { url: baseSrc } = {},
        large: { url: largeSrc } = {},
    } = args[0];

    const index = args[1];

    return <ZoomImage baseSrc={ baseSrc } largeSrc={ largeSrc } index={ index } isMobile={ isMobile } />
};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/renderImage */
export const renderSlide = (args, callback, instance) => {
    const { activeImage } = instance.props;

    const media = args[0];
    const index = args[1];

    const { media_type } = media;

    if (activeImage === undefined) {
        return null;
    }

    switch (media_type) {
    case MediaType.IMAGE:
        return instance.renderImage(media, index);
    case MediaType.VIDEO:
        return instance.renderVideo(media, index);
    case MediaType.PLACEHOLDER:
        return instance.renderPlaceholder(index);
    default:
        return null;
    }
};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/handleSliderClick */
export const handleSliderClick = () => {
    return;
};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/_calculateGallerySize */
export const _calculateGallerySize = (args, callback, instance) => {
    // const originalElement = callback.apply(instance, args);
    // return cloneElement(originalElement);
    const { isMobile } = instance.props;
    const ref = instance.galleryRef.current;

    if (!ref || isMobile) {
        return;
    }
    const { width } = ref.getBoundingClientRect();

    const slidesCount = Math.floor((width - ARROW_SAFE_AREA * 2) / (90 + CAROUSEL_ITEM_GAP));

    instance.setState({ slidesCount: slidesCount });

};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/renderAdditionalPictures */
export const renderAdditionalPictures = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);
    return cloneElement(originalElement);
};

/** @namespace Efex/MorhanePdpGallery/Plugin/Component/ProductGallery/state */
export const state = (originalMember, instance) => ({
    ...originalMember,
    slidesCount: 4,
});

export default {
    'Component/ProductGallery/Component': {
        'member-function': {
            componentDidUpdate,
            handleSliderClick,
            renderImage,
            renderSlide,
            renderAdditionalPictures,
            _calculateGallerySize,
        },
        'member-property': {
            state,
        }
    },
};
